<template>
	<div class="w-full">
		<EnvelopeModal :controller="createModal" @close="closeCreateModal"/>

		<a-card :bodyStyle="{ padding: 0, borderTop: '1px solid var(--light-purple)' }" title="Agreement of Reservation">
			<EnvelopeTable :key="$store.state.appData.newStoreTime" :main="true" :reservation="reservation" tableFor="reservation"/>
			<template slot="actions" v-if="editingRights && needsRelease && mainSigned && requiredMutualRelease">
				<div class="text-left w-full pl-4" style="cursor: auto;">
					<a-tooltip overlayClassName="change-tooltip-color"
						title="Release Envelopes can be used to void this Reservation">
						<a href="#" @click.prevent="e => createEnvelope('release')"
							style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
							<a-icon type="plus-circle" class="mr-2" />
							<span>Add Notice of Mutual Release</span>
						</a>
					</a-tooltip>
				</div>
			</template>
		</a-card>
	</div>
</template>

<script>
import EnvelopeTable from '@/components/transaction/EnvelopeTable'
import EnvelopeModal from '@/components/transaction/EnvelopeModal'
export default {
	components: {
		EnvelopeTable,
		EnvelopeModal,
	},
	data() {
		return {
			formData: null,
			createModal: {
				show: false,
				type: 'regular',
				envelope: false,
				isReservation: true
			}
		}
	},

	computed: {
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		requiredMutualRelease() {
			return this.$store.state.appData.requiredMutualRelease
		},
		mainSigned() {
			return this.reservation.envelopes.find(e => e.main && e.status.includes('complete'))
		},
		needsRelease() {
			let envelopes = this.reservation.envelopes;
			let releaseExist = envelopes.some(
				(d) => d.type && d.type === "release" && d.status !== "voided"
			);
			let sofar = !releaseExist && !this.reservation.status.includes("void");
			return sofar;
		},
		editingRights() {
			if (this.reservation.status === 'void') return false
			let canEdit = false
			if (this.$p >= 20) {
				return true
			} else if (this.reservation.createdBy && this.reservation.createdBy.id == this.user.id) {
				canEdit = true
			}
			return canEdit;
		},
		user() {
			return this.$store.state.user.user
		},
		envelopes() {
			return this.reservation.envelopes.filter(x => x.esign || x.isManual)
		},
		instance() {
			return this.$store.state.instance
		},
	},
	methods: {
		createEnvelope(type = 'regular') {
			this.createModal = {
				show: true,
				type,
				envelope: true,
				isAmendment: true,
				isReservation: true,
			}

		},
		closeCreateModal() {
			this.createModal.show = false
		},
	},
}
</script>

<style>
</style>
