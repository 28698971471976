<template>
	<div class="w-full">
		<participantModal />
		<a-row :gutter="30" type="flex">
			<a-col class="mb-4" v-for="(user, userI) in participants" :key="user.email + userI">
				<participantCards :participant="user" @edituser="editUser(user)"
					@deleteuser="deleteUser(user)" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
import participantCards from '@/components/common/participantCards'
import participantModal from '@/components/common/participantModal'

export default {
	components: {
		participantCards, participantModal
	},
	data() {
		return {

		}
	},

	computed: {
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		signingParty() {
			return this.reservation.signingParty
		},
		participants() {
			return this.$store.getters.currentParticipants.signers
		},
		instance() {
			return this.$store.state.instance
		},
		user() {
			return this.$store.state.user.user
		},
	},
	methods: {
		newCard() {
			this.$store.commit('SHOW_PARTICIPANT_MODAL', { user: {}, type: 'add' })
		},
		editUser(user) {
			this.$store.commit('SHOW_PARTICIPANT_MODAL', { user: user, type: 'edit' })
		},
		deleteUser(user) {
			this.$confirm({
				title: "Delete Participant",
				content: h => <div>Do you want to delete <b>{user.name}</b>?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk: () => {
					this.$store.commit('SET_PROP', { where: ['currentReservation', 'signingParty', user.email], del: true })
					self.$emit('updateReservation')
				}
			})
		},
	},
	created() {

	},
	mounted() {

	}
}
</script>

<style>
</style>
