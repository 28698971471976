<template>
	<div>
		<a-modal :footer="null" :visible="showPurchaserDocs" @cancel="showPurchaserDocs = false">
			<a-carousel arrows class="carousel-modal">
				<template #prevArrow>
					<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
						<svg style="width:20px;height:20px;color:black" viewBox="64 64 896 896" data-icon="arrow-left"
							fill="currentColor" aria-hidden="true" focusable="false" class="">
							<path
								d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z">
							</path>
						</svg>
					</div>
				</template>
				<template #nextArrow>
					<div class="custom-slick-arrow" style="right: 10px">
						<svg style="width:20px;height:20px;color:black" focusable="false" class="" data-icon="arrow-right"
							width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
							<path
								d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z">
							</path>
						</svg>
					</div>
				</template>
				<img :src="img" v-for="(img, imgI) in purchaserImages" alt="Purchaser Docs" :key="img + imgI" />
			</a-carousel>
		</a-modal>

		<div class="contract-details-card general-summary">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Reservation Details
				<div>
					<small style="font-weight:300; background:white; border-radius:40px;" class="px-3 py-2 inline-block">
						<a-badge :status="reservationStatus.color" :text="reservationStatus.text" />
					</small>
				</div>
			</div>

			<a-card v-if="productType === 'lowrise'">
				<a-descriptions layout="horizontal">
					<div slot="title" class="w-full dF jSB aC">
						Summary
					</div>
					<a-descriptions-item label="Reservation Date">
						{{ formatDate(reservation.createdAt) }}
					</a-descriptions-item>
					<a-descriptions-item label="Product">
						Lot {{ product.lot && product.lot.name }} ({{ unit.unitGroup.name }} {{ unit.name }})
					</a-descriptions-item>
					<a-descriptions-item label="Reservation Fee">
						${{ formatNumber(reservation.amount) }} ({{ reservation.currency }})
					</a-descriptions-item>
				</a-descriptions>
			</a-card>

			<a-card v-else-if="productType === 'highrise'">
				<a-descriptions layout="horizontal">
					<div slot="title" class="w-full dF jSB aC">
						Summary
					</div>
					<a-descriptions-item label="Reservation Date">
						{{ formatDate(reservation.createdAt) }}
					</a-descriptions-item>
					<a-descriptions-item label="Unit">
						{{ product.floor.name }} - Unit #{{ product.unit.unitNumber }} ({{ product.unit.name }})
					</a-descriptions-item>
					<a-descriptions-item label="Reservation Fee">
						${{ formatNumber(reservation.amount) }} ({{ reservation.currency }})
					</a-descriptions-item>
				</a-descriptions>
			</a-card>
		</div>

		<div v-if="purchasers.length" class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Purchasers Details
			</div>

			<a-card>
				<div v-for="(purchaser, index) in purchasers" :key="index">
					<a-descriptions layout="horizontal" :column="{ xxl: 5, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }">
						<div slot="title" class="w-full dF jSB aC">
							Purchaser #{{ index + 1 }}
							<a-button @click="viewPurchaserDocs(purchaser)"
								v-if="(purchaser.fileFront && purchaser.fileBack) || purchaser.filePassport" ghost
								type="primary" size="small">View Document(s)</a-button>
						</div>
						<a-descriptions-item label="First Name">
							{{ purchaser.firstName }}
						</a-descriptions-item>
						<a-descriptions-item label="Last Name">
							{{ purchaser.lastName }}
						</a-descriptions-item>
						<a-descriptions-item label="Middle Name">
							{{ purchaser.middleName }}
						</a-descriptions-item>
						<a-descriptions-item label="Email">
							{{ purchaser.email }}
						</a-descriptions-item>
						<a-descriptions-item label="Phone Number">
							{{ purchaser.phone }}
						</a-descriptions-item>
						<a-descriptions-item label="Date of Birth">
							{{ purchaser.dob }}
						</a-descriptions-item>
						<a-descriptions-item label="Business Phone#">
							{{ purchaser.business }}
						</a-descriptions-item>
						<a-descriptions-item label="Cell Phone#">
							{{ purchaser.cell }}
						</a-descriptions-item>
						<a-descriptions-item label="Company">
							{{ purchaser.company }}
						</a-descriptions-item>
						<a-descriptions-item label="Job Title">
							{{ purchaser.jobTitle }}
						</a-descriptions-item>
						<a-descriptions-item label="SSN/SIN #">
							{{ purchaser.ssnsin }}
						</a-descriptions-item>
						<a-descriptions-item label="Driver License #">
							{{ purchaser.idValue }}
						</a-descriptions-item>
						<a-descriptions-item label="License Issuing Jurisdiction">
							{{ purchaser.issuing }}
						</a-descriptions-item>
						<a-descriptions-item label="License Expiry">
							{{ purchaser.expiry }}
						</a-descriptions-item>
						<a-descriptions-item :label="purchaser.idType === 'pp' ? 'Passport #' : 'Other #'">
							{{ purchaser.passportOtherValue }}
						</a-descriptions-item>
						<a-descriptions-item
							:label="purchaser.idType === 'pp' ? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'">
							{{ purchaser.passportOtherIssuing }}
						</a-descriptions-item>
						<a-descriptions-item :label="purchaser.idType === 'pp' ? 'Passport Expiry' : 'Other Expiry'">
							{{ purchaser.passportOtherExpiry }}
						</a-descriptions-item>
						<a-descriptions-item label="Address 1" :span="2">
							{{ purchaser.address1 }}
						</a-descriptions-item>
						<a-descriptions-item label="Address 2">
							{{ purchaser.address2 }}
						</a-descriptions-item>
						<a-descriptions-item label="City">
							{{ purchaser.city }}
						</a-descriptions-item>
						<a-descriptions-item label="State/Province">
							{{ purchaser.region }}
						</a-descriptions-item>
						<a-descriptions-item label="Zip/Postal Code">
							{{ purchaser.postal }}
						</a-descriptions-item>
						<a-descriptions-item label="Country">
							{{ purchaser.country }}
						</a-descriptions-item>
					</a-descriptions>
					<a-divider v-if="(purchasers.length - 1) !== index" class="mt-3"
						style="background: black; margin: 10px 0px 15px"></a-divider>
				</div>
			</a-card>
		</div>
	</div>
</template>

<script>
import { formatDate, formatNumber } from 'bh-mod'

export default {
	data: () => ({
		showPurchaserDocs: false,
		purchaserImages: [],
	}),
	computed: {
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		unit() {
			return this.product.unit
		},
		isComplete() {
			let main = this.reservation.envelopes.find(d => d.main)
			if (!main) return false
			return main.status.incudes('complete')
		},
		reservationStatus() {
			let { status } = this.reservation
			let done = {}, pending = {}
			let envelopes = this.reservation.envelopes

			let color = 'warning'
			let text = 'Reservation Pending'

			if (status === 'completed') {
				color = 'success'
				text = 'All Contracts Signed'
			} else if (status === 'pending' || status === 'created') {
				color = 'warning'
				text = 'Contracts Pending'

				let allPurchaserSigned = false;

				if (!this.reservation.isManual) {
					envelopes.forEach(e => {
						if (!e.esign) return
						if (e.status === 'voided') return
						if (e.main && e.progress && e.progress.length) {
							let totalPurchasers = 0;
							let totalSinged = 0
							e.progress.forEach(s => {
								if (s.roleName && s.roleName.startsWith('Purchaser #')) {
									totalPurchasers++;
									if (s.status === 'completed') {
										totalSinged++;
									}
								}
							})

							if (totalPurchasers === totalSinged) {
								allPurchaserSigned = true;
								text = 'Purchaser Signed';
								color = 'success'
							}
						}
					})
				}

				return { color, text, allPurchaserSigned }
			}
			if (status.includes('void')) {
				color = 'error'
				text = 'Reservation Voided'
				return { color, text }
			}

			if (this.reservation.isManual) {
				let main = this.reservation.envelopes.find(x => x.main)
				let hasPendingManual = this.reservation.envelopes.find(
					x => !x.main && x.esign && !x.status.includes('complete') && !x.status.includes('void')
				)

				color = 'warning'
				text = 'Reservation Agreement Pending'

				if (main.status.includes('void')) {
					return {
						color: 'error',
						text: 'Reservation Voided'
					}
				}
				if (hasPendingManual) {
					return {
						color: 'warning',
						text: 'Pending Documents'
					}
				}
				if (main.status.includes('complete')) {
					return {
						color: 'success',
						text: 'All Contracts Signed',
						isComplete: true,
						isConditional: !this.reservation.isSigned
					}
				}
				return {
					color,
					text,
				}
			}

			if (!envelopes) return { color: 'warning', text: 'No main envelope found!' }

			envelopes.forEach(e => {
				if (!e.esign) return
				if (e.status === 'voided') return
				if (e.main) {
					if (e.signedSoFar === e.signFull) {
						done.main = true
					} else {
						pending.main = true
					}
				} else {
					if (e.signedSoFar === e.signFull) {
						done.others = true
					} else {
						pending.others = true
					}
				}
			})

			if (Object.keys(pending).length > 0) {
				color = 'processing'
				text = pending.main ? 'Reservation Contract Pending' : 'Additional Contract Pending'
			}

			return {
				color,
				text,
				isComplete: done.main,
				isConditional: !this.reservation.isSigned
			}
		},
		productType() {
			return this.instance.productType || 'lowrise'
		},
		instance() {
			return this.$store.state.instance
		},
		product() {
			return this.reservation.product || {}
		},
		purchasers() {
			return this.reservation.purchasers
		},
	},
	methods: {
		formatDate, formatNumber,
		async viewPurchaserDocs(purchaser) {
			this.purchaserImages = [];
			this.$store.commit('LOAD', true);
			if (purchaser.fileFront && purchaser.fileFront.id && purchaser.fileBack && purchaser.fileBack.id) {
				let result = await Promise.all([this.fetchFileSignedUrl(purchaser.fileFront.id), this.fetchFileSignedUrl(purchaser.fileBack.id)]);
				if (result[0]) {
					this.purchaserImages.push(result[0])
				}
				if (result[1]) {
					this.purchaserImages.push(result[1])
				}
			}
			if (purchaser.filePassport && purchaser.filePassport.id) {
				const url = await this.fetchFileSignedUrl(purchaser.filePassport.id)
				if (url) {
					this.purchaserImages.push(url)
				}
			}
			this.$store.commit('LOAD', false);
			this.showPurchaserDocs = true;
		},

		async fetchFileSignedUrl(id) {
			try {
				const { data } = await this.$api.post(`upload/files/get-signed-url/${id}`)
				return data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching image url. Please try again.`))
				}
				return null
			}
		},
	},
	created() {

	}
}
</script>

<style lang="scss">
.carousel-modal>>>.slick-slide {
	text-align: center;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
}

.carousel-modal .custom-slick-arrow {
	background: white;
	border: 7px solid white;
	box-sizing: content-box;
	border-radius: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

	&:hover {
		background: white;
	}
}

.carousel-modal .slick-track {
	display: flex;
	align-items: center;
}

.carousel-modal>>>.slick-slide h3 {
	color: #fff;
}

.carousel-modal {
	.slick-dots li button {
		background: var(--med-gray);
	}

	.slick-dots li.slick-active button {
		background: gray;
	}
}
</style>
